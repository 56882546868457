import React from 'react';
import { Box, LinearProgress, Typography } from '@mui/material';

interface ProgressBarProps {
  totalProgress: number;
  uploadedFilesCount: number;
  totalFiles: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ totalProgress, uploadedFilesCount, totalFiles }) => {
  return (
    <Box mt={2}>
      <Typography variant="body2">
        {uploadedFilesCount}/{totalFiles} files uploaded
      </Typography>
      <LinearProgress variant="determinate" value={totalProgress} />
    </Box>
  );
};

export default ProgressBar;
