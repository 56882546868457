import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { IconButton, Typography, Box } from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import { AppDispatch, RootState } from '../../app/store';
import GenericLayout from '../Common/GenericLayout';
import { selectIsLoading, selectGalleryById } from '../../selectors/gallerySelectors';
import { getGalleryById, updateGallery } from '../../features/gallery/gallerySlice';
import { fetchAlbums, createAlbum, clearAlbums, deleteAlbumById } from '../../features/album/albumSlice';
import GalleriesGrid from '../Gallery/GalleryGrid/GalleryGrid';
import CreateModal from '../Common/CreateModal';
import DeleteConfirmationModal from '../Common/DeleteConfirmationModal';
import UserDetailsOverlay from '../Common/UserDetailsOverlay';
import QRCodeActions from '../Common/QRCodeActions';
import './GalleryDetailPage.css';

const GalleryDetailPage: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { galleryId: paramGalleryId } = useParams<{ galleryId?: string }>();
  const galleryId = paramGalleryId || 'default-id';

  const isLoading = useSelector(selectIsLoading);
  const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);
  const gallerySelector = useSelector((state: RootState) => selectGalleryById(state, galleryId));
  const albums = useSelector((state: RootState) => state.albums.albums);

  const [gallery, setGallery] = useState<any | null>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [albumIdToDelete, setAlbumIdToDelete] = useState<string | null>(null);
  const [isUserVerified, setIsUserVerified] = useState<boolean>(isLoggedIn || Boolean(localStorage.getItem('name') && localStorage.getItem('email')));

  useEffect(() => {
    dispatch(clearAlbums());
    dispatch(getGalleryById(galleryId))
      .unwrap()
      .then((gallery) => {
        setGallery(gallery);
      })
      .catch((error) => {
        navigate('/404');
      });
    dispatch(fetchAlbums(galleryId));
  }, [dispatch, galleryId, navigate]);

  useEffect(() => {
    setGallery(gallerySelector);
  }, [gallerySelector]);

  const handleCreateAlbum = (albumName: string) => {
    dispatch(createAlbum({ name: albumName, galleryId }));
  };

  const handleAlbumClick = (albumId: string) => {
    navigate(`/galleries/${galleryId}/albums/${albumId}`);
  };

  const handleTitleChange = (newTitle: string) => {
    dispatch(updateGallery({ galleryId, updateData: { name: newTitle } }));
  };

  const handleDeleteClick = (event: React.MouseEvent<HTMLElement>, albumId: string) => {
    event.stopPropagation(); // Prevent card click event
    setAlbumIdToDelete(albumId);
    setOpenDeleteDialog(true); // Open the confirmation dialog
  };

  const handleConfirmDelete = async () => {
    if (albumIdToDelete) {
      await dispatch(deleteAlbumById({ galleryId, albumId: albumIdToDelete }));
      setAlbumIdToDelete(null);
      setOpenDeleteDialog(false);
    }
  };

  const createAlbumButton = gallery?.isOwner ? (
    <>
      <IconButton onClick={() => setOpenModal(true)} className="icon-button">
        <AddIcon fontSize="small" />
      </IconButton>
      <QRCodeActions url={window.location.href} title={gallery?.name ?? 'Gallery'} />
      <CreateModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        onCreate={handleCreateAlbum}
        title="Create New Album"
        label="Album Name"
      />
    </>
  ) : null;

  return (
    <>
      <GenericLayout
        isLoading={isLoading}
        title={gallery?.name}
        actions={createAlbumButton}
        editable={gallery?.isOwner}
        onTitleChange={handleTitleChange}
      >
        {!isUserVerified && (
          <UserDetailsOverlay onSave={() => setIsUserVerified(true)} title={gallery?.name} />
        )}

        {isUserVerified && (
          <Box mb={2}>
            {albums.length > 0 ? (
              <>
                <Typography variant="h6" mb={2}>
                  Albums
                </Typography>
                <GalleriesGrid
                  galleries={albums}
                  handleCardClick={handleAlbumClick}
                  handleDeleteClick={gallery?.isOwner ? handleDeleteClick : undefined}
                  showCreatedAt={false}
                  showDelete={true}
                  showItemCount={true}
                />
              </>
            ) : (
              <Typography variant="h6" mb={2}>
                This gallery has no albums
              </Typography>
            )}
          </Box>
        )}
      </GenericLayout>

      <DeleteConfirmationModal
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        onConfirm={handleConfirmDelete}
        description="Deleting the album will also delete all photos/videos within it. Are you sure you want to proceed?"
      />
    </>
  );
};

export default GalleryDetailPage;