// src/components/Album/AlbumHeader.tsx

import React from 'react';
import { Typography } from '@mui/material';

interface AlbumHeaderProps {
  title?: string;
  onTitleChange: (newTitle: string) => void;
}

const AlbumHeader: React.FC<AlbumHeaderProps> = ({ title, onTitleChange }) => {
  return (
    <Typography variant="h4" component="h1" gutterBottom>
      {title}
    </Typography>
  );
};

export default AlbumHeader;