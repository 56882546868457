// Snackbar.tsx
import React from 'react';
import { Snackbar, Alert } from '@mui/material';
import { AlertColor } from '@mui/material/Alert';

interface CustomSnackbarProps {
  open: boolean;
  onClose: () => void;
  message: string;
  severity: AlertColor;
}

const CustomSnackbar: React.FC<CustomSnackbarProps> = ({ open, onClose, message, severity }) => {
  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={onClose}>
      <Alert onClose={onClose} severity={severity} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default CustomSnackbar;
