// src/components/Common/NotFoundPage.tsx
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Box, Typography } from '@mui/material';

const NotFoundPage: React.FC = () => {
  const navigate = useNavigate();

  const handleBackToDashboard = () => {
    navigate('/dashboard');
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      textAlign="center"
      padding="20px"
    >
      <Typography variant="h3" gutterBottom>
        Lumos
      </Typography>
      <Box mb={4} /> {/* Adding some margin below the Lumos heading */}
      <Typography variant="h4" gutterBottom>
        404 - Page Not Found
      </Typography>
      <Typography variant="body1" gutterBottom>
        The page you are looking for does not exist.
      </Typography>
      <Button variant="contained" color="primary" onClick={handleBackToDashboard}>
        Back to Dashboard
      </Button>
    </Box>
  );
};

export default NotFoundPage;