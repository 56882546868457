import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { RootState } from '../../app/store';
import { logout } from '../../features/auth/authSlice';

export const useAuthCheck = () => {
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'error' | 'warning' | 'info' | 'success'>('warning');

  const dispatch = useDispatch();
  const navigate = useNavigate(); // Make sure useNavigate is used inside a component or hook within Router
  const token = useSelector((state: RootState) => state.auth.token);

  useEffect(() => {
    if (token) {
      try {
        const decodedToken: any = jwtDecode(token);
        const currentTime = Date.now() / 1000; // Convert to seconds

        if (decodedToken.exp < currentTime) {
          // Token has expired
          dispatch(logout());
          setSnackbarMessage('Your session has expired.');
          setSnackbarSeverity('warning');
          setIsSnackbarOpen(true);
          navigate('/login'); // Navigate to login page
        }
      } catch (error) {
        console.error('Error decoding token:', error);
      }
    }
  }, [token, dispatch, navigate]);

  const handleSnackbarClose = () => {
    setIsSnackbarOpen(false);
  };

  return {
    isSnackbarOpen,
    snackbarMessage,
    snackbarSeverity,
    handleSnackbarClose,
  };
};