import React, { useState, useEffect } from 'react';
import { Button, TextInputField, Alert } from 'evergreen-ui';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { signupUser } from '../../features/auth/authSlice';
import { AppDispatch } from '../../app/store';
import './AuthForm.css';
import { LocationState } from '../../types/location';

const SignupForm = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [emailError, setEmailError] = useState('');
    const [signupError, setSignupError] = useState('');
    const [isFormValid, setIsFormValid] = useState(false);
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const location = useLocation();

    // Attempt to get the redirect path from location state or default to '/dashboard'
    const { from } = location.state as LocationState || { from: { pathname: "/dashboard" } };

    const validateEmail = (email: string) => {
        const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return re.test(String(email).toLowerCase());
    };

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newEmail = e.target.value;
        setEmail(newEmail);
        if (newEmail && !validateEmail(newEmail)) {
            setEmailError('Please enter a valid email address');
        } else {
            setEmailError('');
        }
        setSignupError(''); // Clear any previous signup errors when email changes
    };

    useEffect(() => {
        // Check if all fields are filled and email is valid
        setIsFormValid(
            name.trim() !== '' &&
            email.trim() !== '' &&
            password.trim() !== '' &&
            validateEmail(email)
        );
    }, [name, email, password]);

    const handleSignup = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!validateEmail(email)) {
            setEmailError('Please enter a valid email address');
            return;
        }
        try {
            // Dispatch the signupUser action with name, email, and password
            await dispatch(signupUser({ email, password, name })).unwrap();
            navigate(from);
        } catch (err: any) {
            console.error('Signup failed:', err);
            if (err.error) {
                setSignupError(err.error);
            } else {
                setSignupError('An error occurred during signup. Please try again.');
            }
        }
    };

    return (
        <div className="auth-container">
            <h1 className="auth-heading">Lumos</h1>
            <div className="auth-form-container">
                <form onSubmit={handleSignup} className="auth-form">
                    <TextInputField
                        label="Name"
                        required
                        value={name}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
                    />
                    <TextInputField
                        label="Email"
                        required
                        value={email}
                        onChange={handleEmailChange}
                        isInvalid={!!emailError}
                    />
                    {emailError && (
                        <Alert intent="danger" marginBottom={16}>
                            <p className="small-error-text">{emailError}</p>
                        </Alert>
                    )}
                    <TextInputField
                        label="Password"
                        required
                        type="password"
                        value={password}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                    />
                    {signupError && (
                        <Alert intent="danger" marginBottom={16}>
                            <p className="small-error-text">{signupError}</p>
                        </Alert>
                    )}
                    <Button type="submit" appearance="primary" disabled={!isFormValid}>
                        Sign Up
                    </Button>
                </form>
            </div>
        </div>
    );
};

export default SignupForm;