import React, { forwardRef } from 'react';
import QRCode from 'qrcode.react';
import { Typography, Box } from '@mui/material';
import './PrintableSharePage.css';

interface PrintableSharePageProps {
  url: string;
}

const PrintableSharePage = forwardRef<HTMLDivElement, PrintableSharePageProps>(({ url }, ref) => (
  <Box ref={ref} className="printable-page" p={4}>
    <Typography variant="h4" gutterBottom>
      Join my Gallery!
    </Typography>
    <QRCode value={url} size={300} />
    <Typography variant="body1" mt={2}>
      Join by scanning the QR code and upload your favourite photos and videos
    </Typography>
  </Box>
));

export default PrintableSharePage;