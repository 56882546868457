import JSZip from 'jszip';
import { saveAs } from 'file-saver';

interface FileToDownload {
  name: string;
  url: string;
}

export const downloadAndZipFiles = async (
  files: FileToDownload[],
  albumName: string,
  onProgress: (progress: number) => void
) => {
  const zip = new JSZip();
  let downloadedSize = 0;
  const totalSize = files.length; // We're using file count as a simple progress metric

  const downloadPromises = files.map(async (file, index) => {
    const response = await fetch(file.url);
    const blob = await response.blob();
    zip.file(file.name, blob);

    downloadedSize++;
    onProgress((downloadedSize / totalSize) * 100);
  });

  await Promise.all(downloadPromises);

  const zipBlob = await zip.generateAsync({ type: 'blob' });
  saveAs(zipBlob, `${albumName}.zip`);
};