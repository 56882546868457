import React from 'react';
import { Card, CardContent, CardActions, Typography, IconButton, Grid, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import styles from './GalleryGrid.module.css';
import { Gallery } from '../../../features/gallery/gallerySlice';
import { formatLocalDateTime } from '../../../utils/DateFormat';

type GalleriesGridProps = {
  galleries: Gallery[];
  handleCardClick: (galleryId: string) => void;
  handleDeleteClick?: (event: React.MouseEvent<HTMLElement>, galleryId: string) => void;
  showCreatedAt?: boolean;
  showDelete?: boolean;
  showItemCount?: boolean; // Optional property to show item count
};

const GalleriesGrid: React.FC<GalleriesGridProps> = ({
  galleries,
  handleCardClick,
  handleDeleteClick,
  showCreatedAt = true,
  showDelete = false,
  showItemCount = false, // Default to not showing item count
}) => {
  return (
    <Grid container spacing={2} className={styles.galleriesGrid}>
      {galleries.map((gallery) => (
        <Grid
          item
          xs={6}    // 2 cards per row on extra small screens
          sm={6}    // 2 cards per row on small screens
          md={4}    // 3 cards per row on medium screens and above
          key={gallery.id}
          className={styles.gridItem}
        >
          <Card className={styles.card} onClick={() => handleCardClick(gallery.id)}>
            <CardContent className={styles.cardContent}>
              <Typography gutterBottom variant="h6" component="div" className={styles.galleryName}>
                {gallery.name}
              </Typography>
              {showItemCount && gallery.itemCount !== undefined && (
                <Typography variant="body2" color="textSecondary" className={styles.itemCount}>
                  {gallery.itemCount} items
                </Typography>
              )}
            </CardContent>
            <CardActions className={styles.cardActions}>
              {showCreatedAt && (
                <Tooltip title={`Created at: ${formatLocalDateTime(gallery.createdAt)}`} arrow>
                  <IconButton size="small" className={styles.iconButton}>
                    <AccessTimeIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
              {showDelete && handleDeleteClick && (
                <IconButton
                  onClick={(event) => handleDeleteClick(event, gallery.id)}
                  className={styles.iconButton}
                  size="small"
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              )}
            </CardActions>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default GalleriesGrid;
