import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Backdrop } from '@mui/material';
import BottomBar from './BottomBar/BottomBar';
import TopBar from './TopBar';
import './GenericLayout.css';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/store';

interface GenericLayoutProps {
  children: React.ReactNode;
  isLoading?: boolean;
  title?: string;
  actions?: React.ReactNode;
  backButton?: boolean;
  editable?: boolean; // New prop to decide if the title is editable
  onTitleChange?: (newTitle: string) => void; // Callback for title change
}

const GenericLayout: React.FC<GenericLayoutProps> = ({
  children,
  isLoading = false,
  title,
  actions,
  backButton = true,
  editable = false,
  onTitleChange,
}) => {
  const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);

  return (
    <div className="generic-layout">
      {isLoggedIn ? <BottomBar /> : ''}
      <div className="main-content">
        <TopBar
          title={title}
          actions={actions}
          backButton={backButton}
          editable={editable}
          onTitleChange={onTitleChange}
        />
        {isLoading ? (
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        ) : (
          <div className="content-area">{children}</div>
        )}
      </div>
    </div>
  );
};

export default GenericLayout;
