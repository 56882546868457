import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { HomeOutlined, LoginOutlined } from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router-dom';
import { BottomNavigation, BottomNavigationAction } from '@mui/material';

import { AppDispatch } from '../../../app/store';
import { logout } from '../../../features/auth/authSlice';

import './BottomBar.css';

const MainBottomBar: React.FC = () => {
	const dispatch = useDispatch<AppDispatch>();
	const [value, setValue] = useState(0);
	const navigate = useNavigate();
	const location = useLocation();
	
	const handleLogout = () => {
		dispatch(logout());
		navigate('/login'); // Redirect to login page after logout
	};

	// Update value based on the current route
	React.useEffect(() => {
		if (location.pathname === '/dashboard') setValue(0);
	}, [location.pathname]);

	return (
		<BottomNavigation
			showLabels
			value={value}
			onChange={(event, newValue) => {
				setValue(newValue);
				if (newValue === 0) navigate('/dashboard');
				if (newValue === 1) handleLogout();
			}}
			className="bottom-navigation"
		>
			<BottomNavigationAction label="Home" icon={<HomeOutlined />} />
			<BottomNavigationAction label="Logout" icon={<LoginOutlined />} />
		</BottomNavigation>
	);
};

export default MainBottomBar;
