import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, TextInputField, Alert, Pane, Text } from 'evergreen-ui';
import { useNavigate, useLocation } from 'react-router-dom';
import { resetPassword, resetPasswordResetStatuses } from '../../features/auth/authSlice';
import { RootState, AppDispatch } from '../../app/store';
import './AuthForm.css';

const PasswordResetForm: React.FC = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [token, setToken] = useState('');
  const [error, setError] = useState<string | null>(null);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();
  const resetStatus = useSelector((state: RootState) => state.auth.resetStatus);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tokenFromUrl = searchParams.get('token');
    if (tokenFromUrl) {
      setToken(tokenFromUrl);
    } else {
      setError('Invalid or missing reset token');
    }

    // Reset the status when the component mounts
    dispatch(resetPasswordResetStatuses());

    // Reset the status when the component unmounts
    return () => {
      dispatch(resetPasswordResetStatuses());
    };
  }, [location, dispatch]);

  const handleResetPassword = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);

    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    dispatch(resetPassword({ token, newPassword: password }));
  };

  useEffect(() => {
    if (resetStatus === 'succeeded') {
      setTimeout(() => navigate('/login'), 3000);
    }
  }, [resetStatus, navigate]);

  return (
    <div className="auth-container">
      <h1 className="auth-heading">Reset Password</h1>
      <div className="auth-form-container">
        <form onSubmit={handleResetPassword} className="auth-form">
          <TextInputField
            label="New Password"
            type="password"
            required
            value={password}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
          />
          <TextInputField
            label="Confirm New Password"
            type="password"
            required
            value={confirmPassword}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setConfirmPassword(e.target.value)}
          />
          {error && (
            <Alert intent="danger" marginBottom={16}>
              <Pane>
                <Text className="small-error-text">{error}</Text>
              </Pane>
            </Alert>
          )}
          {resetStatus === 'failed' && (
            <Alert intent="danger" marginBottom={16}>
              <Pane>
                <Text className="small-error-text">Failed to reset password. Please try again.</Text>
              </Pane>
            </Alert>
          )}
          {resetStatus === 'succeeded' && (
            <Alert intent="success" marginBottom={16}>
              <Pane>
                <Text>Password reset successfully. You can now log in with your new password.</Text>
              </Pane>
            </Alert>
          )}
          <Button type="submit" appearance="primary" isLoading={resetStatus === 'loading'}>
            Reset Password
          </Button>
        </form>
      </div>
    </div>
  );
};

export default PasswordResetForm;