import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../axiosConfig';

interface AuthState {
    isLoggedIn: boolean;
    status: 'idle' | 'loading' | 'failed';
    token: string | null;
    resetRequestStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
    resetStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
}

const initialState: AuthState = {
    isLoggedIn: false,
    status: 'idle',
    token: null,
    resetRequestStatus: 'idle',
    resetStatus: 'idle',
};

export const loginUser = createAsyncThunk(
    'auth/loginUser',
    async (userData: { email: string; password: string }, { rejectWithValue }) => {
        try {
            const response = await axios.post('/api/auth/login', userData);
            return response.data.token;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    }
);

export const signupUser = createAsyncThunk(
    'auth/signupUser',
    async (userData: { email: string; password: string; name: string }, { rejectWithValue }) => {
        try {
            const response = await axios.post('/api/auth/signup', userData);
            return response.data.token;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    }
);

export const requestPasswordReset = createAsyncThunk(
    'auth/requestPasswordReset',
    async (email: string, { rejectWithValue }) => {
        try {
            const response = await axios.post('/api/auth/request-password-reset', { email });
            return response.data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    }
);

export const resetPassword = createAsyncThunk(
    'auth/resetPassword',
    async (resetData: { token: string; newPassword: string }, { rejectWithValue }) => {
        try {
            const response = await axios.post('/api/auth/reset-password', resetData);
            return response.data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    }
);

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setAuthToken: (state, action: PayloadAction<string | null>) => {
            state.token = action.payload;
            state.isLoggedIn = !!action.payload;
        },
        logout: (state) => {
            state.isLoggedIn = false;
            state.token = null;
            localStorage.removeItem('token');
        },
        resetPasswordResetStatuses: (state) => {
            state.resetRequestStatus = 'idle';
            state.resetStatus = 'idle';
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginUser.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(loginUser.fulfilled, (state, action) => {
                state.status = 'idle';
                state.isLoggedIn = true;
                state.token = action.payload;
                localStorage.setItem('token', action.payload);
            })
            .addCase(loginUser.rejected, (state) => {
                state.status = 'failed';
                state.isLoggedIn = false;
            })
            .addCase(signupUser.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(signupUser.fulfilled, (state, action) => {
                state.status = 'idle';
                state.isLoggedIn = true;
                state.token = action.payload;
                localStorage.setItem('token', action.payload);
            })
            .addCase(signupUser.rejected, (state) => {
                state.status = 'failed';
                state.isLoggedIn = false;
            })
            .addCase(requestPasswordReset.pending, (state) => {
                state.resetRequestStatus = 'loading';
            })
            .addCase(requestPasswordReset.fulfilled, (state) => {
                state.resetRequestStatus = 'succeeded';
            })
            .addCase(requestPasswordReset.rejected, (state) => {
                state.resetRequestStatus = 'failed';
            })
            .addCase(resetPassword.pending, (state) => {
                state.resetStatus = 'loading';
            })
            .addCase(resetPassword.fulfilled, (state) => {
                state.resetStatus = 'succeeded';
            })
            .addCase(resetPassword.rejected, (state) => {
                state.resetStatus = 'failed';
            });
    },
});

export const { setAuthToken, logout, resetPasswordResetStatuses } = authSlice.actions;
export default authSlice.reducer;