import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Box, Typography } from '@mui/material';
import CustomSnackbar from '../Snackbar/Snackbar';

interface FileUploadProps {
  onDrop: (acceptedFiles: File[]) => void;
}

const FileUpload: React.FC<FileUploadProps> = ({ onDrop }) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const maxFileSize = 500 * 1024 * 1024; // 500 MB

  const handleDrop = (acceptedFiles: File[]) => {
    const filteredFiles = acceptedFiles.filter(file => {
      if (file.size > maxFileSize) {
        setSnackbarMessage(`File ${file.name} is too large. Maximum allowed size is 500 MB.`);
        setSnackbarOpen(true);
        return false;
      }
      return true;
    });

    if (filteredFiles.length > 0) {
      onDrop(filteredFiles);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleDrop,
    accept: {
      'image/*': [],
      'video/*': [],
    },
  });

  return (
    <>
      <Box className="dropzone-container" {...getRootProps()}>
        <input {...getInputProps()} />
        <Typography variant="body1">
          Drag & drop photos or videos here, or click to select files
        </Typography>
      </Box>
      <CustomSnackbar
        open={snackbarOpen}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        severity="error"
      />
    </>
  );
};

export default FileUpload;
