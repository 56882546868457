import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton, Typography, Box } from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import { clearMediaFiles, createGallery, deleteGallery, fetchGalleries } from '../../features/gallery/gallerySlice';
import GenericLayout from '../Common/GenericLayout';
import { AppDispatch } from '../../app/store';
import './DashboardPage.css';
import { selectIsLoading, selectGalleries } from '../../selectors/gallerySelectors';
import GalleriesGrid from '../Gallery/GalleryGrid/GalleryGrid';
import CreateModal from '../Common/CreateModal';
import DeleteConfirmationModal from '../Common/DeleteConfirmationModal';

const DashboardPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const galleries = useSelector(selectGalleries);
  const isLoading = useSelector(selectIsLoading);
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [galleryIdToDelete, setGalleryIdToDelete] = useState<string | null>(null);

  useEffect(() => {
    dispatch(fetchGalleries());
    dispatch(clearMediaFiles());
  }, [dispatch]);

  const handleCardClick = (galleryId: string) => {
    navigate(`/galleries/${galleryId}`);
  };

  const handleDeleteClick = (event: React.MouseEvent<HTMLElement>, galleryId: string) => {
    event.stopPropagation(); // Prevent card click event
    setGalleryIdToDelete(galleryId);
    setOpenDeleteDialog(true); // Open the confirmation dialog
  };

  const handleConfirmDelete = async () => {
    if (galleryIdToDelete) {
      await dispatch(deleteGallery(galleryIdToDelete));
      setGalleryIdToDelete(null);
      setOpenDeleteDialog(false);
    }
  };

  const handleCreateGallery = (galleryName: string) => {
    dispatch(createGallery({ name: galleryName }));
  };

  return (
    <GenericLayout
      isLoading={isLoading}
      title="Lumos"
      backButton={false}
      actions={
        <IconButton className="icon-button" onClick={() => setOpenModal(true)}>
          <AddIcon fontSize="small" />
        </IconButton>
      }
    >
      {/* Reusable Deletion Confirmation Modal */}
      <DeleteConfirmationModal
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        onConfirm={handleConfirmDelete}
        description="Deleting the gallery will also delete all albums and their associated photos/videos. Are you sure you want to proceed?"
      />

      <CreateModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        onCreate={handleCreateGallery}
        title="Create New Gallery"
        label="Gallery Name"
      />

      <Box mb={2}>
        <Typography variant="h6" mb={2}>
          Galleries
        </Typography>
        <GalleriesGrid
          galleries={galleries}
          handleCardClick={handleCardClick}
          handleDeleteClick={handleDeleteClick}
          showCreatedAt={true}
          showDelete={true}
        />
      </Box>
    </GenericLayout>
  );
};

export default DashboardPage;
