import React, { useState } from 'react';
import { Button, TextInputField, Alert, Pane, Text } from 'evergreen-ui';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { loginUser } from '../../features/auth/authSlice';
import { AppDispatch } from '../../app/store';
import './AuthForm.css'; // Updated import
import { LocationState } from '../../types/location';

const LoginForm = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState<string | null>(null);
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const location = useLocation();

    // Attempt to get the redirect path from location state or default to '/dashboard'
    const { from } = location.state as LocationState || { from: { pathname: "/dashboard" } };

    const handleLogin = async (e: React.FormEvent) => {
        e.preventDefault();
        setError(null); // Clear any existing errors
        try {
            const userData = { email, password };
            await dispatch(loginUser(userData)).unwrap();
            // Login successful, redirect to the previous page or default page
            navigate(from);
        } catch (err) {
            // Handle login error
            setError('Email or password is incorrect');
            console.error('Login failed:', err);
        }
    };

    return (
        <div className="auth-container">
            <h1 className="auth-heading">Lumos</h1>
            <div className="auth-form-container">
                <form onSubmit={handleLogin} className="auth-form">
                    <TextInputField
                        label="Email"
                        required
                        value={email}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                    />
                    <TextInputField
                        label="Password"
                        required
                        type="password"
                        value={password}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                    />
                    {error && (
                        <Alert
                            intent="danger"
                            marginBottom={16}
                        >
                            <Pane>
                                <Text className="small-error-text">{error}</Text>
                            </Pane>
                        </Alert>
                    )}
                    <Button type="submit" appearance="primary">Login</Button>
                </form>
            </div>
            <p className="signup-text">
                Don't have an account? <Link to="/signup">Sign up</Link>
            </p>
            <p className="signup-text">
                Forgot your password? <Link to="/request-password-reset">Reset it here</Link>
            </p>
        </div>
    );
};

export default LoginForm;