import React from 'react';
import { Box, Chip } from '@mui/material';

interface MediaFilterProps {
  filter: 'all' | 'uploadedByYou';
  onFilterChange: (filter: 'all' | 'uploadedByYou') => void;
}

const MediaFilter: React.FC<MediaFilterProps> = ({ filter, onFilterChange }) => {
  return (
    <Box display="flex" justifyContent="flex-start" mb={2}>
      <Chip
        label="All"
        clickable
        color={filter === 'all' ? 'primary' : 'default'}
        onClick={() => onFilterChange('all')}
        sx={{ marginRight: 1 }}
      />
      <Chip
        label="Uploaded by You"
        clickable
        color={filter === 'uploadedByYou' ? 'primary' : 'default'}
        onClick={() => onFilterChange('uploadedByYou')}
      />
    </Box>
  );
};

export default MediaFilter;
