import React from 'react';
import { CircularProgress, Typography, styled } from '@mui/material';

const ProgressContainer = styled('div')(({ theme }) => ({
  position: 'fixed',
  bottom: 16,
  left: '50%',
  transform: 'translateX(-50%)',
  zIndex: 1000,
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[3],
  borderRadius: theme.shape.borderRadius * 2,
  padding: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
}));

interface DownloadProgressIndicatorProps {
  progress: number;
}

const DownloadProgressIndicator: React.FC<DownloadProgressIndicatorProps> = ({ progress }) => {
  return (
    <ProgressContainer>
      <CircularProgress variant="determinate" value={progress} size={40} />
      <div style={{ marginLeft: '16px' }}>
        <Typography variant="body2" color="text.secondary">
          Downloading: {progress.toFixed(0)}%
        </Typography>
        <Typography variant="caption" color="text.secondary">
          Please don't refresh or leave the page
        </Typography>
      </div>
    </ProgressContainer>
  );
};

export default DownloadProgressIndicator;