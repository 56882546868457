import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, Typography } from '@mui/material';
import { Check as CheckIcon } from '@mui/icons-material';

interface UserDetailsOverlayProps {
  onSave: () => void;
  title: string; // New prop for the title
}

const UserDetailsOverlay: React.FC<UserDetailsOverlayProps> = ({ onSave, title }) => {
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');

  useEffect(() => {
    // Check if name and email are already in local storage
    const storedName = localStorage.getItem('name');
    const storedEmail = localStorage.getItem('email');

    if (storedName && storedEmail) {
      onSave(); // If both are stored, consider the user "saved"
    }
  }, [onSave]);

  const handleSave = () => {
    if (name.trim() && email.trim()) {
      localStorage.setItem('name', name);
      localStorage.setItem('email', email);
      onSave();
    }
  };

  return (
    <Dialog open fullWidth maxWidth="xs">
      <DialogTitle style={{ textAlign: 'center' }}>Welcome to {title}</DialogTitle>
      <DialogContent>
        <Typography variant="body1" style={{ textAlign: 'center', marginBottom: '20px' }}>
          To continue, please enter your name and email.
        </Typography>
        <TextField
          variant="standard"
          label="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          size="medium"
          fullWidth
          margin="dense"
        />
        <TextField
          variant="standard"
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          size="medium"
          fullWidth
          margin="dense"
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleSave}
          startIcon={<CheckIcon fontSize="small" />}
          variant="contained"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserDetailsOverlay;
