import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, TextInputField, Alert, Pane, Text } from 'evergreen-ui';
import { Link } from 'react-router-dom';
import { requestPasswordReset, resetPasswordResetStatuses } from '../../features/auth/authSlice';
import { RootState, AppDispatch } from '../../app/store';
import './AuthForm.css';

const PasswordResetRequestForm: React.FC = () => {
  const [email, setEmail] = useState('');
  const dispatch = useDispatch<AppDispatch>();
  const resetRequestStatus = useSelector((state: RootState) => state.auth.resetRequestStatus);

  useEffect(() => {
    // Reset the status when the component mounts
    dispatch(resetPasswordResetStatuses());

    // Reset the status when the component unmounts
    return () => {
      dispatch(resetPasswordResetStatuses());
    };
  }, [dispatch]);

  const handleResetRequest = async (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(requestPasswordReset(email));
  };

  return (
    <div className="auth-container">
      <h1 className="auth-heading">Reset Password</h1>
      <div className="auth-form-container">
        <form onSubmit={handleResetRequest} className="auth-form">
          <TextInputField
            label="Email"
            required
            value={email}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
          />
          {resetRequestStatus === 'failed' && (
            <Alert intent="danger" marginBottom={16}>
              <Pane>
                <Text className="small-error-text">Failed to send reset email. Please try again.</Text>
              </Pane>
            </Alert>
          )}
          {resetRequestStatus === 'succeeded' && (
            <Alert intent="success" marginBottom={16}>
              <Pane>
                <Text>Password reset email sent. Please check your inbox.</Text>
              </Pane>
            </Alert>
          )}
          <Button type="submit" appearance="primary" isLoading={resetRequestStatus === 'loading'}>
            Request Reset
          </Button>
        </form>
      </div>
      <p className="signup-text">
        Remember your password? <Link to="/login">Log in</Link>
      </p>
    </div>
  );
};

export default PasswordResetRequestForm;