import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField } from '@mui/material';
import { Check as CheckIcon, Close as CloseIcon } from '@mui/icons-material';

interface CreateModalProps {
  open: boolean;
  onClose: () => void;
  onCreate: (name: string) => void;
  title: string;
  label: string;
  initialValue?: string;
  isUpdate?: boolean; // New prop to control if the modal is for updating
}

const CreateModal: React.FC<CreateModalProps> = ({
  open,
  onClose,
  onCreate,
  title,
  label,
  initialValue = '',
  isUpdate = false, // Default to false if not provided
}) => {
  const [name, setName] = useState<string>(initialValue);

  useEffect(() => {
    if (initialValue) {
      setName(initialValue); // Update the name state when the initialValue changes
    }
  }, [initialValue]);

  const handleCreate = () => {
    if (name.trim()) {
      onCreate(name);
      setName(''); // Reset input after creation
      onClose();
    }
  };

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <TextField
          variant="standard"
          label={label}
          value={name}
          onChange={(e) => setName(e.target.value)}
          size="small"
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          startIcon={<CloseIcon fontSize="small" />}
          style={{ color: 'black' }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleCreate}
          startIcon={<CheckIcon fontSize="small" />}
          style={{ color: 'black' }}
        >
          {isUpdate ? 'Update' : 'Create'} {/* Dynamic button text */}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateModal;
