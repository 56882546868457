// src/components/Album/AlbumDetailPage.tsx

import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { AppDispatch, RootState } from '../../app/store';
import GenericLayout from '../Common/GenericLayout';
import {
  deleteAlbumMedia,
  fetchAlbumById,
  fetchAlbumMedia,
  updateAlbum,
  uploadFilesToAlbum,
  downloadAllAlbumMedia,
  clearDownloadLinks
} from '../../features/album/albumSlice';
import { selectAlbumById } from '../../selectors/albumSelectors';
import UserDetailsOverlay from '../Common/UserDetailsOverlay';
import AlbumHeader from './AlbumHeader';
import MediaActions from './MediaActions';
import MediaFilter from './MediaFilter';
import ProgressBar from '../Common/ProgressBar';
import PhotoGallery from './PhotoGallery';
import { Media } from '../../types/media';
import QRCodeActions from '../Common/QRCodeActions';
import CustomSnackbar from '../Snackbar/Snackbar';
import { downloadAndZipFiles } from '../../utils/downloader';
import DownloadProgressIndicator from './DownloadProgressIndicator';

const AlbumDetailPage: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { galleryId = '', albumId = '' } = useParams<{ galleryId?: string; albumId?: string }>();

  const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);
  const [media, setMedia] = useState<Media[]>([]);
  const [totalProgress, setTotalProgress] = useState<number>(0);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [uploadedFilesCount, setUploadedFilesCount] = useState<number>(0);
  const [totalFiles, setTotalFiles] = useState<number>(0);
  const [isUserVerified, setIsUserVerified] = useState<boolean>(
    isLoggedIn || Boolean(localStorage.getItem('name') && localStorage.getItem('email'))
  );
  const [filter, setFilter] = useState<'all' | 'uploadedByYou'>('all');
  const [isSelectMode, setIsSelectMode] = useState<boolean>(false);
  const [selectedPhotos, setSelectedPhotos] = useState<string[]>([]);
  const initialFetchDone = useRef(false);

  const album = useSelector((state: RootState) => selectAlbumById(state, albumId));
  const nextContinuationToken = useSelector((state: RootState) => state.albums.nextContinuationToken);
  const isLoading = useSelector((state: RootState) => state.albums.status === 'loading' || isUploading);
  const downloadLinks = useSelector((state: RootState) => state.albums.downloadLinks);

  const [downloadProgress, setDownloadProgress] = useState<number | null>(null);

  const [hasMore, setHasMore] = useState(true);
  const [isFetching, setIsFetching] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'info' | 'warning' | 'error'>('info');

  const fetchMediaItems = useCallback((token?: string) => {
    if (!hasMore || isFetching) return;

    setIsFetching(true);
    dispatch(fetchAlbumMedia({ galleryId, albumId, maxKeys: 10, continuationToken: token }))
      .unwrap()
      .then((response) => {
        console.log('Fetched media:', response);
        const formattedMedia = response.files.map((file: any) => ({
          src: file.url,
          key: file.key,
          userIdentifier: file.userIdentifier,
          type: file.type,
          thumbnailUrl: file.thumbnailUrl,
        }));
        setMedia((prevMedia) => [...prevMedia, ...formattedMedia]);
        setHasMore(!!response.nextContinuationToken);
      })
      .catch((error) => {
        console.error('Error fetching media:', error);
        setHasMore(false);
      })
      .finally(() => {
        setIsFetching(false);
      });
  }, [dispatch, galleryId, albumId, hasMore, isFetching]);

  const handleFileDrop = useCallback(
    (acceptedFiles: File[]) => {
      setTotalProgress(0);
      setIsUploading(true);
      setUploadedFilesCount(0);
      setTotalFiles(acceptedFiles.length);

      console.log('Accepted files:', acceptedFiles.map(f => ({ name: f.name, type: f.type, size: f.size })));

      const progressArray = new Array(acceptedFiles.length).fill(0);
      const hasVideos = acceptedFiles.some(file => file.type.startsWith('video/'));

      const onProgress = (index: number, progress: number) => {
        progressArray[index] = progress;
        const totalProgress = progressArray.reduce((acc, curr) => acc + curr, 0) / acceptedFiles.length;
        setTotalProgress(totalProgress);

        if (progress === 100) {
          setUploadedFilesCount((prevCount) => prevCount + 1);
        }
      };

      dispatch(uploadFilesToAlbum({ galleryId, albumId, files: acceptedFiles, onProgress }))
        .unwrap()
        .then((result) => {
          console.log('Upload completed, fetching new media');
          setIsUploading(false);
          setTotalProgress(0);
          setUploadedFilesCount(0);

          let message = '';
          if (result.files && result.files.length > 0) {
            message += `Successfully uploaded ${result.files.length} file(s). `;
          }

          if (result.rejectedFiles && result.rejectedFiles.length > 0) {
            const rejectedFileNames = result.rejectedFiles.map(f => `${f.name} (${f.reason})`).join(', ');
            message += `The following files were not uploaded: ${rejectedFileNames}`;
            setSnackbarSeverity('warning');
          } else {
            setSnackbarSeverity('success');
          }

          if (hasVideos) {
            message += ' Video files are being optimized for web viewing. This process may take a few minutes.';
            setSnackbarSeverity('info');
          }

          setSnackbarMessage(message);
          setSnackbarOpen(true);

          // Refresh the media list
          setMedia([]); // Clear existing media
          setHasMore(true); // Reset hasMore
          fetchMediaItems(); // Fetch all media items again
        })
        .catch((error) => {
          console.error('Error uploading files:', error);
          setIsUploading(false);
          setSnackbarMessage('An error occurred while uploading files.');
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
        });
    },
    [dispatch, galleryId, albumId, fetchMediaItems]
  );

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    if (isUserVerified) {
      dispatch(fetchAlbumById({ albumId }))
        .unwrap()
        .catch(() => navigate('/404'));
    }
  }, [dispatch, albumId, isUserVerified, navigate]);

  useEffect(() => {
    if (isUserVerified && album && !initialFetchDone.current && media.length === 0) {
      initialFetchDone.current = true;
      fetchMediaItems();
    }
  }, [isUserVerified, album, fetchMediaItems, media.length]);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "20px",
      threshold: 1.0
    };

    const observer = new IntersectionObserver((entities) => {
      const target = entities[0];
      if (target.isIntersecting && hasMore && !isFetching && !isLoading) {
        fetchMediaItems(nextContinuationToken || undefined);
      }
    }, options);

    const loader = document.querySelector('#loader');
    if (loader) observer.observe(loader);

    return () => {
      if (loader) observer.unobserve(loader);
    };
  }, [fetchMediaItems, hasMore, isFetching, isLoading, nextContinuationToken]);

  const handleTitleChange = (newTitle: string) => {
    if (album) {
      dispatch(updateAlbum({ albumId, updateData: { name: newTitle } }));
    }
  };

  const getGalleryUrl = useCallback(() => {
    const currentUrl = window.location.href;
    const galleryUrl = currentUrl.split('/albums/')[0];
    return galleryUrl;
  }, []);

  const handleDownloadAll = useCallback(() => {
    if (album && album.isOwner) {
      setDownloadProgress(0);
      dispatch(downloadAllAlbumMedia({ galleryId, albumId }))
        .unwrap()
        .catch((error) => {
          console.error('Download failed:', error);
          setSnackbarMessage(error.message || 'Download failed. Please try again.');
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
          setDownloadProgress(null);
        });
    }
  }, [dispatch, galleryId, albumId, album]);

  useEffect(() => {
    if (downloadLinks.length > 0) {
      downloadAndZipFiles(
        downloadLinks,
        album?.name || 'album',
        (progress) => setDownloadProgress(progress)
      ).then(() => {
        setDownloadProgress(null);
        dispatch(clearDownloadLinks());
      }).catch((error) => {
        console.error('Download failed:', error);
        setSnackbarMessage('Download failed. Please try again.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        setDownloadProgress(null);
        dispatch(clearDownloadLinks());
      });
    }
  }, [downloadLinks, album, dispatch]);

  const handleSelectModeToggle = () => {
    setIsSelectMode(!isSelectMode);
    setSelectedPhotos([]);
  };

  const handleDeleteSelected = useCallback(() => {
    if (selectedPhotos.length > 0) {
      dispatch(deleteAlbumMedia({ galleryId, keys: selectedPhotos }))
        .unwrap()
        .then(() => {
          setSelectedPhotos([]);
          setIsSelectMode(false);
          setSnackbarMessage(`Successfully deleted ${selectedPhotos.length} item(s)`);
          setSnackbarSeverity('success');
          setSnackbarOpen(true);

          // Refresh the media list
          setMedia([]); // Clear existing media
          setHasMore(true); // Reset hasMore
          fetchMediaItems(); // Fetch all media items again
        })
        .catch((error) => {
          console.error('Error deleting files:', error);
          setSnackbarMessage('An error occurred while deleting files.');
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
        });
    }
  }, [dispatch, galleryId, selectedPhotos, fetchMediaItems]);

  const filteredMedia = media.filter((item) => {
    if (filter === 'all') return true;
    return item.userIdentifier === 'You';
  });

  return (
    <GenericLayout
      isLoading={isUserVerified && isLoading && media.length === 0}
      title={album?.name}
      actions={
        <>
          <MediaActions
            isOwner={!!album?.isOwner}
            isSelectMode={isSelectMode}
            onToggleSelectMode={handleSelectModeToggle}
            onDeleteSelected={handleDeleteSelected}
            onDownloadAll={handleDownloadAll}
            selectedPhotosCount={selectedPhotos.length}
            onFileDrop={handleFileDrop}
          />
          {album && !!album?.isOwner && (
            <QRCodeActions
              url={getGalleryUrl()}
              title={`${album.name} (Gallery)`}
            />
          )}
        </>
      }
      editable={album?.isOwner}
      onTitleChange={handleTitleChange}
    >
      {!isUserVerified && !isLoggedIn && (
        <UserDetailsOverlay onSave={() => setIsUserVerified(true)} title={album?.name ?? ''} />
      )}

      {isUserVerified && (
        <>
          <AlbumHeader title={album?.name} onTitleChange={handleTitleChange} />

          {media.length > 0 && (
            <MediaFilter filter={filter} onFilterChange={setFilter} />
          )}

          {isUploading && (
            <ProgressBar
              totalProgress={totalProgress}
              uploadedFilesCount={uploadedFilesCount}
              totalFiles={totalFiles}
            />
          )}

          <PhotoGallery
            photos={filteredMedia}
            isOwner={!!album?.isOwner}
            isSelectMode={isSelectMode}
            onPhotoSelect={setSelectedPhotos}
            selectedPhotos={selectedPhotos}
          />

          {hasMore && <div id="loader" style={{ height: "20px" }}></div>}

          {media.length === 0 && !isLoading && !isFetching && (
            <p>No media found in this album.</p>
          )}
        </>
      )}
      {downloadProgress !== null && (
        <DownloadProgressIndicator progress={downloadProgress} />
      )}
      <CustomSnackbar
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </GenericLayout>
  );
};

export default AlbumDetailPage;