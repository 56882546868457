// utils/formatDate.ts

/**
 * Converts a UTC date string to a more readable format in the user's local timezone.
 * @param utcDateString The UTC date string to convert.
 * @returns A string representing the date and time in a readable format along with the user's local timezone.
 */
export function formatLocalDateTime(utcDateString: string): string {
  const date = new Date(utcDateString);
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric', month: 'long', day: 'numeric',
    hour: '2-digit', minute: '2-digit', second: '2-digit', timeZoneName: 'short'
  };
  return new Intl.DateTimeFormat('default', options).format(date);
}
