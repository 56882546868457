import { createSelector } from "reselect";
import { RootState } from "../app/store";

const getAlbumState = (state: RootState) => state.albums;

export const getAlbumNameById = (state: RootState, albumId: string) =>
  state.albums.albums.find(album => album.id === albumId)?.name;

export const selectAlbumById = (state: RootState, albumId: string) =>
  state.albums.albums.find(album => album.id === albumId);

export const selectIsLoading = createSelector(getAlbumState, (albums) => albums.status === 'loading');

export const selectAlbums = createSelector(getAlbumState, (albums) => albums.albums)