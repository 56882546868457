import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowBack, Edit as EditIcon } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import './TopBar.css';
import CreateModal from '../Common/CreateModal';

interface TopBarProps {
  title?: string;
  backButton?: boolean;
  actions?: React.ReactNode;
  editable?: boolean; // New prop to decide if the title is editable
  onTitleChange?: (newTitle: string) => void; // Callback for title change
}

const TopBar: React.FC<TopBarProps> = ({ title, backButton = true, actions, editable = false, onTitleChange }) => {
  const navigate = useNavigate();
  const [newTitle, setNewTitle] = useState(title || '');
  const [openModal, setOpenModal] = useState(false);

  // Sync newTitle with title prop
  useEffect(() => {
    if (title) {
      console.log(title)
      setNewTitle(title);
    }
  }, [title]);

  const handleBack = () => {
    navigate(-1);
  };

  const handleTitleChange = (newTitle: string) => {
    if (onTitleChange) {
      onTitleChange(newTitle);
    }
  };

  return (
    <header className="top-bar">
      {backButton && (
        <span onClick={handleBack} style={{ color: 'black', cursor: 'pointer' }}>
          <ArrowBack fontSize='small' className='icon-button'/>
        </span>
      )}
      {title && (
        <div className="top-bar-title">
          {title}
          {editable && (
            <IconButton onClick={() => setOpenModal(true)} size="small" style={{ marginLeft: 8 }}>
              <EditIcon fontSize="small" />
            </IconButton>
          )}
        </div>
      )}
      {actions && (
        <div className="top-bar-actions">
          {actions}
        </div>
      )}
      <CreateModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        onCreate={handleTitleChange}
        title="Edit Title"
        label="Title"
        initialValue={newTitle} // Pre-fill the modal with the current title
        isUpdate={true}
      />
    </header>
  );
};

export default TopBar;
