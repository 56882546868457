import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../axiosConfig';

export interface Gallery {
  id: string;
  name: string;
  isOwner: boolean;
  createdAt: string;
  itemCount?: number;
}

interface GalleryState {
  galleries: Gallery[];
  mediaFiles: string[]; // Media files for the currently active gallery
  currentGalleryId: string | null; // Track the currently active gallery ID
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null | undefined;
}

const initialState: GalleryState = {
  galleries: [],
  mediaFiles: [],
  currentGalleryId: null,
  status: 'idle',
  error: null,
};

export const createGallery = createAsyncThunk(
  'gallery/createGallery',
  async (galleryData: { name: string }, { getState, rejectWithValue }) => {
    try {
      const state: any = getState();
      const token = state.auth.token;

      // Generate a unique ID for the gallery (or this could be done by the backend)
      const newGallery = { ...galleryData };

      const response = await axios.post('/api/galleries', newGallery, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data; // Assuming the server echoes the newly created gallery, including the unique ID
    } catch (err: any) {
      return rejectWithValue(err.response?.data || err.message);
    }
  }
);

export const fetchGalleries = createAsyncThunk(
  'galleries/fetchGalleries',
  async (_, { getState, rejectWithValue }) => {
    try {
      const state: any = getState();
      const token = state.auth.token;

      const response = await axios.get('/api/galleries', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data; // Assuming the server returns an array of galleries
    } catch (err: any) {
      return rejectWithValue(err.response?.data || err.message);
    }
  }
);

export const updateGallery = createAsyncThunk(
  'gallery/updateGallery',
  async ({ galleryId, updateData }: { galleryId: string; updateData: Partial<Gallery> }, { getState, rejectWithValue }) => {
    try {
      const state: any = getState();
      const token = state.auth.token;

      const response = await axios.put(`/api/galleries/${galleryId}`, updateData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data; // Assuming the server returns the updated gallery object
    } catch (err: any) {
      return rejectWithValue(err.response?.data || err.message);
    }
  }
);

export const deleteGallery = createAsyncThunk(
  'gallery/deleteGallery',
  async (galleryId: string, { getState, rejectWithValue }) => {
    try {
      const state: any = getState();
      const token = state.auth.token;

      await axios.delete(`/api/galleries/${galleryId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return galleryId; // Return the id of the deleted gallery
    } catch (err: any) {
      return rejectWithValue(err.response?.data || err.message);
    }
  }
);

export const getGalleryById = createAsyncThunk(
  'gallery/getGalleryById',
  async (galleryId: string, { getState, rejectWithValue }) => {
    try {
      const state: any = getState();
      const token = state.auth.token;

      const headers: any = {};
      if (token) {
        headers.Authorization = `Bearer ${token}`;
      }

      const response = await axios.get(`/api/galleries/${galleryId}`, { headers });
      return response.data; // Assuming the server returns the gallery object
    } catch (err: any) {
      return rejectWithValue(err.response?.data.message || err.message);
    }
  }
);

export const fetchGalleryMedia = createAsyncThunk(
  'gallery/fetchGalleryMedia',
  async (galleryId: string, { dispatch, rejectWithValue }) => {
    try {
      // Clear the previous media files before fetching new ones
      dispatch(clearMediaFiles());

      const response = await axios.get(`/api/media/fetch-event-files/${galleryId}`);
      return { galleryId, files: response.data.files }; // Return both galleryId and files
    } catch (err: any) {
      return rejectWithValue(err.response?.data || err.message);
    }
  }
);


const gallerySlice = createSlice({
  name: 'galleries',
  initialState,
  reducers: {
    clearMediaFiles: (state) => {
      state.mediaFiles = [];
      state.currentGalleryId = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Handling createGallery actions
      .addCase(createGallery.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createGallery.fulfilled, (state, action) => {
        state.galleries.push(action.payload);
        state.status = 'succeeded';
      })
      .addCase(createGallery.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message ? action.error.message : null;
      })
      // Handling fetchGallery actions
      .addCase(fetchGalleries.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchGalleries.fulfilled, (state, action) => {
        state.galleries = action.payload;
        state.status = 'succeeded';
      })
      .addCase(fetchGalleries.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message ? action.error.message : null;
      })
      // Handling getGalleryById actions
      .addCase(getGalleryById.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getGalleryById.fulfilled, (state, action) => {
        const gallery = state.galleries.find(g => g.id === action.payload.id);
        if (gallery) {
          // Update existing gallery with fetched data
          Object.assign(gallery, action.payload);
        } else {
          // Add the gallery to the state if it's not already there
          state.galleries.push(action.payload);
        }
        state.status = 'succeeded';
      })
      .addCase(getGalleryById.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message ? action.error.message : null;
      })
      // Handling deleteGalleries actions
      .addCase(deleteGallery.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteGallery.fulfilled, (state, action) => {
        state.galleries = state.galleries.filter(gallery => gallery.id !== action.payload);
        state.status = 'succeeded';
      })
      .addCase(deleteGallery.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message ? action.error.message : null;
      })
      .addCase(updateGallery.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateGallery.fulfilled, (state, action) => {
        const existingGallery = state.galleries.find(gallery => gallery.id === action.payload.id);
        if (existingGallery) {
          Object.assign(existingGallery, action.payload); // Update the existing album with new data
        }
        state.status = 'succeeded';
      })
      .addCase(updateGallery.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message ? action.error.message : null;
      })
      // Handling fetchGalleryMedia actions
      .addCase(fetchGalleryMedia.pending, (state, action) => {
        state.status = 'loading';
        state.mediaFiles = []; // Clear media files when starting to fetch new ones
      })
      .addCase(fetchGalleryMedia.fulfilled, (state, action) => {
        state.mediaFiles = action.payload.files; // Store the new media files
        state.currentGalleryId = action.payload.galleryId; // Track the current gallery
        state.status = 'succeeded';
      })
      .addCase(fetchGalleryMedia.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message ? action.error.message : null;
      });
  },
});
export const { clearMediaFiles } = gallerySlice.actions;

export default gallerySlice.reducer;
