import React from 'react';
import { Outlet } from 'react-router-dom';
import CustomSnackbar from '../Snackbar/Snackbar';
import { useAuthCheck } from '../Auth/AuthCheck';

const Layout: React.FC = () => {
  const { isSnackbarOpen, snackbarMessage, snackbarSeverity, handleSnackbarClose } = useAuthCheck();

  return (
    <>
      <Outlet /> {/* This will render the matched route component */}
      <CustomSnackbar
        open={isSnackbarOpen}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
};

export default Layout;