import React from 'react';
import { IconButton, Popover, Box } from '@mui/material';
import { Add as AddIcon, Delete as DeleteIcon, SelectAll as SelectModeIcon, CloudDownload as CloudDownloadIcon } from '@mui/icons-material';
import FileUpload from './FileUpload';

interface MediaActionsProps {
  isOwner: boolean;
  isSelectMode: boolean;
  onToggleSelectMode: () => void;
  onDeleteSelected: () => void;
  onDownloadAll: () => void;
  selectedPhotosCount: number;
  onFileDrop: (acceptedFiles: File[]) => void;
}

const MediaActions: React.FC<MediaActionsProps> = ({
  isOwner,
  isSelectMode,
  onToggleSelectMode,
  onDeleteSelected,
  onDownloadAll,
  selectedPhotosCount,
  onFileDrop,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFileDrop = (acceptedFiles: File[]) => {
    onFileDrop(acceptedFiles);
    handleClose();
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <IconButton onClick={handleButtonClick} className="icon-button">
        <AddIcon fontSize="small" />
      </IconButton>
      {isOwner && (
        <IconButton onClick={onDownloadAll} className="icon-button">
          <CloudDownloadIcon />
        </IconButton>
      )}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box p={2}>
          <FileUpload onDrop={handleFileDrop} />
        </Box>
      </Popover>

      <IconButton onClick={onToggleSelectMode} color={isSelectMode ? 'primary' : 'default'}>
        <SelectModeIcon />
      </IconButton>
      <IconButton onClick={onDeleteSelected} color="error" disabled={selectedPhotosCount === 0}>
        <DeleteIcon />
      </IconButton>

    </>
  );
};

export default MediaActions;